module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Dana by Iurco","short_name":"Dana","start_url":"/","icon":"static/icon.png","background_color":"#FFFFFF","theme_color":"#7600FF","display":"standalone"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-165939378-1","head":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"AW-623063377","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"Landing DANA"}},"routeChangeEventName":"AW-623063377/ukLYCMmogNQBENHijKkC"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
